<template>
	<div>
		<div class= "block-title">
			<h1 class="title-search title-custom2">{{$t("titles.customer")}}</h1>
			<div v-if="dayjs(user.unregistration).isValid()" class="subdate">
				<div :class="`dateblock${ (!user.lname || user.lname.length == 0) ? ' required' : '' }`">
					<span class="">{{$t("user.unregistered")}} : </span>
					<span class="date">{{dayjs(user.unregistration).isValid() ? $d( dayjs(user.unregistration), 'longonlydate' ) : ''}}</span>
				</div>
				<!--div v-if="user.userid" class="online-user">Utilisateur de l’Application Espace-temps</div-->
			</div>
			<div v-else class="subdate">
				<div :class="`dateblock${ (!user.lname || user.lname.length == 0) ? ' required' : '' }`">
					<span class="">{{$t("user.registered")}} : </span>
					<span class="date">{{dayjs(user.registration).isValid() ? $d( dayjs(user.registration), 'longonlydate' ) : ''}}</span>
				</div>
				<!--div v-if="user.userid" class="online-user">Utilisateur de l’Application Espace-temps</div-->
			</div>
			<h1 class="title-search title-custom">{{$t("titles.customer")}}</h1>
			<div class="title-select-year desktop">
				<Popper
					class="dropdown d-block years-dropdown"
					trigger="clickToToggle"
					:options="{ placement: 'bottom' }"
					:visible-arrow="false"
					ref="yearsPopper1"
				>
					<div class="dropdown-menu" @click="$refs.yearsPopper1.doClose()">
						<div class="dropdown-choices">
							<button
								v-for="year in allYears"
								class="dropdown-item"
								@click="yearHistory = 1900 + year.getYear(); updateHistory()"
							>{{ 1900 + year.getYear() }}</button>
						</div>
					</div>
					<button
						slot="reference"
						ref="yearSelector"
						aria-expanded="false"
						aria-haspopup="true"
						class="btn btn-secondary dropdown-toggle years"
						data-flip="false"
						data-toggle="dropdown"
					>{{ yearHistory }}</button>
				</Popper>
			</div>
		</div>
		<div class="users row">
			<div class="col-md-6">
				<form id="edit-form" onsubmit="event.preventDefault()" class="block form">
					<div class="input-group form-group">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.gender")}}</span>
						</div>
						
						<Popper
							id="gender_drop"
							class="dropdown d-block"
							trigger="clickToToggle"
							ref="genderPopper"
							:options="{ placement: 'bottom' }"
							:visible-arrow="false"
							:disabled="!!user.unregistration || !!user.userid"
						>
							<div class="dropdown-menu" @click="$refs.genderPopper.doClose()">
								<div class="dropdown-choices">
									<button class="dropdown-item" :disabled="!!user.unregistration || !!user.userid" @click="user.gender = 'female'" type="button">{{ $t("data.genders.female") }}</button>
									<button class="dropdown-item" :disabled="!!user.unregistration || !!user.userid" @click="user.gender = 'male'" type="button">{{ $t("data.genders.male") }}</button>
								</div>
							</div>
							<button
								id="gender"
								slot="reference"
								aria-expanded="false"
								aria-haspopup="true"
								class="btn btn-gray dropdown-toggle "
								data-flip="false"
								data-toggle="dropdown"
								name="btn-gender"
								type="button"
								:disabled="!!user.unregistration || !!user.userid"
							>{{ user.gender ? $t('data.genders.' + user.gender) : '' }}</button>
						</Popper>
					</div>
					<div :class="`input-group form-group${ !user.userid && (!user.lname || user.lname.length == 0 || error.lname) ? ' required' : '' }`">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.lname")}}</span>
						</div>
						<input class="form-control " name="lname" type="text" :disabled="!!user.unregistration || !!user.userid" v-model="user.lname">
					</div>
					<div :class="`input-group form-group${ !user.userid && (!user.fname || user.fname.length == 0 || error.fname) ? ' required' : '' }`">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.fname")}}</span>
						</div>
						<input class="form-control" name="fname" type="text" :disabled="!!user.unregistration || !!user.userid" v-model="user.fname">
					</div>
					<div :class="`input-group form-group${ !user.userid && (!user.birthdate || user.birthdate.length == 0 || error.birthdate) ? ' required' : '' }`">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.datebirth")}}</span>
						</div>
						<div id="birthdate" class="input-group date" data-target-input="nearest">
							<input class="form-control datetimepicker-input" data-target="#birthdate" name="birthdate" onkeydown="return false" type="text" :disabled="!!user.unregistration || !!user.userid">
							<span class="input-group-append" data-target="#birthdate" data-toggle="datetimepicker">
								<div class="input-group-text btn btn-secondary">
									<i class="fa fa-calendar"></i>
								</div>
							</span>
						</div>
					</div>
					<div :class="`input-group form-group${ !user.userid && (user.email && error.email) ? ' required' : '' }`">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.email")}}</span>
						</div>
						<input class="form-control " name="email" type="text" :disabled="!!user.unregistration || !!user.userid" v-model="user.email">
					</div>
					<div :class="`input-group form-group${ !user.userid && (!user.phone || user.phone.length == 0 || error.phone) ? ' required' : '' }`">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.phone")}}</span>
						</div>
						<input class="form-control " name="phone" type="text" :disabled="!!user.unregistration || !!user.userid" v-model="user.phone">
						<div class="input-group-append">
							<a id="btn-call" class="input-group-text btn btn-secondary" :href="user.unregistration ? undefined : ( 'tel:' + user.phone )"><i class="fas fa-phone-square"></i></a>
						</div>
					</div>
					<div :class="`input-group form-group${ error.loyalty ? ' required' : ' zref' }`">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.loyalty")}}</span>
						</div>
						<input id="loyalty" class="form-control " type="text" v-model="user.loyalty">
						<div class="input-group-append">
							<button class="btn-loyalty btn btn-secondary input-group-text" @click="( user.loyalty > 0 ) ? user.loyalty-- : null" type="button">-</button>
							<button class="btn-loyalty btn btn-secondary input-group-text" @click="user.loyalty++" type="button">+</button>
						</div>
					</div>
				</form>
				<div v-if="user.favorite_esthetics_hairdresser == $store.state.salon.id" id="is-favorite" class="text-muted">
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="star">
						<text x="0" y="16">&#xf004</text>
					</svg>
					<span>{{ $t('user.favorite_esthetics_hairdresser') }}</span>
				</div>
				<div class="checkbox checkbox-primary text-muted italic user-forbid">
					<input id="prevent-online" class="styled d-none" type="checkbox" :disabled="!!user.unregistration" v-model="user.prevent_online">
					<label class="d-inline-block" for="prevent-online">
						<div class="checkbox-text">{{ $t("common.forbidcust") }}</div>
					</label>
				</div>
				<div id="save-block">
					<StatusButton ref="userSave" id="user-save" type="primary" :enabled="canSave" :hidden="!showSave" @click="save">{{ $t('common.save') }}</StatusButton>
				</div>
			</div>
			<div class="col-md-6">
				<div>
					<!--template v-if="nextAppointment">
						<h2 class="title-search1">{{$t("titles.nextvisit")}}</h2>
						<div class="history-text history-notifs nextapp">
							<div>
								<div class="history-date">{{ $d( new Date(nextAppointment.date), "long" ) }}</div><br>
								<div class="prestations-text">{{ nextAppointment.title }}</div>
							</div>
							<div class="edit-buttons">
								<button @click="showEditAppointmentModal = nextAppointment" class="btn btn-outline-gray" type="button">{{$t("common.mod")}}</button>
								<StatusButton @click="showDeleteApointmentConfirm = { id: nextAppointment.id, callback: $event };" alert="modal" type="outline-danger">{{$t("common.cancel")}}</StatusButton>
								<!-- TBD div class="card-link" href="#"></div>
							</div>
						</div>
					</template-->
						<Popper
							class="dropdown d-block years-dropdown mobile"
							trigger="clickToToggle"
							:options="{ placement: 'bottom' }"
							:visible-arrow="false"
							ref="yearsPopper2"
						>
							<div class="dropdown-menu" @click="$refs.yearsPopper2.doClose()">
								<div class="dropdown-choices">
									<button
										v-for="year in allYears"
										class="dropdown-item"
										@click="yearHistory = 1900 + year.getYear(); updateHistory()"
									>{{ 1900 + year.getYear() }}</button>
								</div>
							</div>
							<button
								slot="reference"
								ref="yearSelector"
								aria-expanded="false"
								aria-haspopup="true"
								class="btn btn-secondary dropdown-toggle years"
								data-flip="false"
								data-toggle="dropdown"
							>{{ yearHistory }}</button>
						</Popper>
					<div class="row tab-widget">
						<div class="row tab-widget-wrapper w-100">
							<h2
								:class="{ desktop: historylist.length == 0, active: viewTab === 'visits' }"
								class="title-search1 tab-widget-button"
								@click="viewTab = 'visits'"
							>
								<span class="anim-underline">{{$t("customer.visits")}}</span>
							</h2>
							<h2
								v-if="productsHistory.length > 0"
								class="title-search1 tab-widget-button"
								:class="{ active: viewTab === 'colref' }"
								@click="productsHistory.length > 0 && (viewTab = 'colref')"
							>
								<span class="anim-underline">
									{{$t("customer.colref")}}
								</span>
							</h2>
							<h2
								v-if="$store.state.worker.admin && reviews.length > 0"
								:class="{ desktop: reviews.length == 0, active: viewTab === 'reviews' }"
								class="title-search1 tab-widget-button"
								@click="reviews.length > 0 && (viewTab = 'reviews')"
							>
								<span class="anim-underline">
									{{$t("titles.reviews")}}
								</span>
							</h2>
						</div>
					</div>
					<div v-if="viewTab === 'visits'" id="history-card" class="card input-group">
						<div class="history">
							<div id="history-wrapper">
								<div>
									<ul class="self-history expandable expanded">
										<!--li class="history-text history-notifs nextapp">
											<div>
												<div class="history-date">{{ $d( new Date(nextAppointment.date), "long" ) }}</div><br>
												<div class="prestations-text">{{ nextAppointment.title }}</div>
											</div>
											<div class="edit-buttons">
												<button @click="showEditAppointmentModal = nextAppointment" class="btn btn-outline-gray" type="button">{{$t("common.mod")}}</button>
												<StatusButton @click="showDeleteApointmentConfirm = { id: nextAppointment.id, callback: $event };" alert="modal" type="outline-danger">{{$t("common.cancel")}}</StatusButton>
												<!-- TBD div class="card-link" href="#"></div>
											</div>
										</li-->
										<div v-if="historylist.length === 0" class="no-visit">
											{{ $t('common.novisit') }}
										</div>
										<li v-for="history in historylist" :key="history.id">
											<div class="history-text history-notifs d-flex">
												<div class="texts">
													<div class="history-date">{{ $d( new Date(history.date), "long" ) }}</div><br>
													<div class="d-flex">
														<span class="prestations-text" :style="{ color: (history.status||'').indexOf('canceled') >= 0 ? '#777' : undefined }">{{ history.title }}</span>
														<span class="text-muted status" v-if="(history.status||'').indexOf('canceled') >= 0">{{ $t('common.canceled') }}</span>
														<span class="text-muted status" v-else-if="(history.status||'').indexOf('absent') >= 0">{{ $t('common.absent') }}</span>
													</div>
												</div>
												<div v-if="(!history.status || history.status == '') && dayjs(history.date).isAfter(dayjs().set(0,'hour'))" class="edit-buttons">
													<button @click="showEditAppointmentModal = history" class="btn btn-outline-gray" type="button">{{$t("common.mod")}}</button>
													<StatusButton @click="showDeleteApointmentConfirm = { id: history.id, callback: $event };" alert="modal" type="outline-danger">{{$t("common.cancel")}}</StatusButton>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<template v-if="viewTab === 'reviews'">
						<div v-if="$store.state.worker.admin && reviews.length > 0" id="reviews-index-title">{{$t("common.dispute")}}</div>
						<ul id="reviews">
							<li v-for="review in reviews" :key="review.id">
								<div v-if="$store.state.worker.admin" class="checkbox checkbox-primary">
									<input id="front-page-52" checked="" class=" styled d-none" @click = "showOnWebsite" type="checkbox">
									<label class="d-inline-block" for="front-page-52">
										<div class="checkbox-text"></div>
									</label>
								</div>
								<div>
									{{ $d( new Date(review.date), "long" ) }}
									:<br>«
									{{ review.text }}
									»
								</div>
								<div v-if="$store.state.worker.admin" class="right">
									<button v-if="$store.state.worker.admin && review.date && dayjs(review.date).isAfter(dayjs().add(-7, 'day')) && !review.dispute_response" :disabled="!!review.dispute" class="btn btn-primary remove-review" @click="disputeReview=review">
										<!--i class="fas fa-trash-alt"></i-->
										<i v-if="!!review.dispute" class="far fa-hourglass"></i>
										<template v-else>
											{{ $t('common.dispute') }}
										</template>
									</button>
								</div>
							</li>
						</ul>
					</template>
					<div v-if="viewTab === 'colref'" :class="{desktop:productsHistory.length == 0}" id="stock-history">
						<ul>
							<li v-for="product in productsHistory">
								<span class="date">{{ $d( new Date(product.date), "longonlydate" ) }}</span>
								:
								<span><b>{{ product.stock.name }}</b> - {{ product.stock.description }} <router-link :to="'/stocks?id=' + product.stockid">( réf. {{ product.stock.reference }} )</router-link></span>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<StatusButton v-if="!user.unregistration" id="user-delete" type="danger" @click="showDeleteConfirm = $event">{{ $t('customer.delete') }}</StatusButton>
			</div>
		</div>
		<Confirm v-if="showDeleteConfirm" ref="deleteConfirm">
			{{ $t('user.confirmdelete') }}
		</Confirm>
		<Confirm v-if="deleteReviewModalCallback" :title="$t('common.del')" @close="deleteReviewModalCallback(-1); deleteReviewModalCallback = null" @confirm="deleteReview(showDeleteReviewID)">
			{{ $t('index.surereview') }}
		</Confirm>
		<NewAppointment v-if="showEditAppointmentModal" @close="showEditAppointmentModal = false" :edit="showEditAppointmentModal" name="newAppointment"/>
		<Confirm v-if="showDeleteApointmentConfirm" @confirm="deleteAppointment( showDeleteApointmentConfirm.id, showDeleteApointmentConfirm.callback )" @close="showDeleteApointmentConfirm.callback( -1 ); showDeleteApointmentConfirm = null" ref="statusConfirm">
			{{ $t('appointments.confirmcancel') }}
		</Confirm>
		<DisputeReview v-if="disputeReview" @close="disputeReview=null" :review="disputeReview">
		</DisputeReview>
	</div>
</template>


<script>
	import dayjs from "dayjs"
	import api from "../apiClient"
	import Popper from "vue-popperjs"
	import StatusButton from "../components/StatusButton.vue"
	import NewAppointment from "../modals/NewAppointment.vue"
	import DisputeReview from '../modals/DisputeReview.vue'
	import Confirm from "../modals/Confirm.vue"

	export default {
		components: {
			Popper,
			DisputeReview,
			Confirm,
			NewAppointment,
			StatusButton
		},
		data() {
			return {
				dayjs,
				user: {},
				changed: {},
				error: {},
				canSave: false,
				showSave: false,
				showDeleteConfirm: false,
				nextAppointment: null,
				showEditAppointmentModal: null,
				showDeleteApointmentConfirm: null,
				deleteReviewModalCallback: false,
				disputeReview: null,
				historylist: [],
				productsHistory: [],
				reviews: [],
				yearHistory: 1900 + new Date().getYear(),
				viewTab: 'visits',
			}
		},
		computed: {
			allYears() {
				let ret = []
				let date = new Date( this.$store.state.salon.subscription_date )
				while ( date.getYear() <= new Date().getYear() ) {
					ret.push( date )
					date = date.addYears( 1 )
				}
				return ret
			},
		},
		watch: {
			user: {
				handler( val, oldVal ) {
					this.changed = true
				},
				deep: true
			}
		},
		methods: {
			showOnWebsite() {
				//TODO
			},
			deleteAppointment( id, callback ) {
				this.$api.appointment.patch( id, { status: "canceled" } ).then( response => {
					callback( true )
					this.showDeleteApointmentConfirm = false
				}).catch( error => {
					callback( false, error.response.data.error )
				})
			},
			deleteReview(id) {
				this.$api.review.delete(id).then( response => {
					this.deleteReviewModalCallback( true )
					this.deleteReviewModalCallback = null
					setTimeout( () => {
						this.reviews = this.reviews.filter( r => r.id != id )
					}, 750 )
				}).catch( error => {
					console.log( error )
					this.deleteReviewModalCallback( false, error.response.data.error )
				})
			},
			async updateHistory() {
				this.historylist = []
				this.productsHistory = []
				let historyparams = {
					userid: this.$route.query.id
				}
				if ( this.yearHistory ) {
					historyparams["date[gte]"] = this.yearHistory + "-01-01"
					historyparams["date[lte]"] = this.yearHistory + "-12-31"
				}
				const response = await this.$api.appointments.get( { ...historyparams/*, "status[~*]": "finished"*/ } )
				const history = response.data
				history.forEach(element => {
					if ( !element.title ) {
						const titles = []
						if ( element.services ) {
							element.services.forEach(prestation_id => {
								titles.push(this.$store.getters.getService(prestation_id).title)
							})
						}
						element.title = titles.join(" - ")
					}
				})
				this.historylist = history.sort( (a,b) => dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1 )
				const res2 = await this.$api.stockshistory.get( historyparams )
				console.log('Response', res2, historyparams)
				const productsHistory = res2.data
				productsHistory.forEach(element => {
					element.stock = this.$store.getters.getStock( element.stockid )
				})
				this.productsHistory = productsHistory.sort( ( a, b ) => dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1 )
			},
			update() {
				this.error = {}
				if ( this.$refs.userSave ) {
					this.$refs.userSave.reset()
				}

				if ( this.unwatches ) {
					this.unwatches.forEach( f => f() )
					this.unwatches = null
				}

				$(this.$el).find('#birthdate').datetimepicker( 'defaultDate', "" )
				this.changed = {}
				this.reviews = []
				this.canSave = false
				this.showSave = false
				if ( this.$route.query.id ) {
					this.$store.getters.getUser( this.$route.query.id ).then( response => {
						var user = response.data[0]
						user.loyalty = user.loyalty || 0
						let date = user.birthdate ? new Date( user.birthdate ) : ""
						$(this.$el).find('#birthdate').datetimepicker( 'defaultDate', date )
						$(this.$el).find('#birthdate').datetimepicker( 'date', date )
						this.user = user
						this.$api.appointments.get({ "{sort}": "asc:date", userid: user.id, "date[gte]": dayjs().format( "YYYY-MM-DD HH:mm" ), "{max}": 1 }).then( response => {
							this.nextAppointment = response.data[0]
							if ( this.nextAppointment ) {
								this.nextAppointment.workerid = this.nextAppointment.workers[0]
							}
						}).catch(error => {
							console.log(error)
						})
						this.updateHistory()
						if ( user.userid ) {
							this.$api.reviews.get({userid : user.userid}).then(response => {
								var reviews = response.data
								this.reviews = reviews
							}).catch(error => console.log(error))
						}
						this.$watch( 'user', {
							handler( val, oldVal ) {
							//	console.log( val, oldVal )
							},
							deep: true
						})
						let unwatches = []
						unwatches.push( this.$watch( 'user.gender', ( val, oldVal ) => {
							this.changed["gender"] = ( val != oldVal )
							this.canSave |= ( val != oldVal )
							this.showSave |= ( val != oldVal )
						}))
						unwatches.push( this.$watch( 'user.lname', ( val, oldVal ) => {
							this.changed["lname"] = ( val != oldVal )
							this.canSave |= ( val != oldVal )
							this.showSave |= ( val != oldVal )
						}))
						unwatches.push( this.$watch( 'user.fname', ( val, oldVal ) => {
							this.changed["fname"] = ( val != oldVal )
							this.canSave |= ( val != oldVal )
							this.showSave |= ( val != oldVal )
						}))
						unwatches.push( this.$watch( 'user.birthdate', ( val, oldVal ) => {
							this.changed["birthdate"] = ( val != oldVal )
							this.canSave |= ( val != oldVal )
							this.showSave |= ( val != oldVal )
						}))
						unwatches.push( this.$watch( 'user.email', ( val, oldVal ) => {
							this.changed["email"] = ( val != oldVal )
							this.canSave |= ( val != oldVal )
							this.showSave |= ( val != oldVal )
						}))
						unwatches.push( this.$watch( 'user.phone', ( val, oldVal ) => {
							this.changed["phone"] = ( val != oldVal )
							this.canSave |= ( val != oldVal )
							this.showSave |= ( val != oldVal )
						}))
						unwatches.push( this.$watch( 'user.loyalty', ( val, oldVal ) => {
							this.changed["loyalty"] = ( val != oldVal )
							this.canSave |= ( val != oldVal )
							this.showSave |= ( val != oldVal )
						}))
						unwatches.push( this.$watch( 'user.prevent_online', ( val, oldVal ) => {
							this.changed["prevent_online"] = ( val != oldVal )
							this.canSave |= ( val != oldVal )
							this.showSave |= ( val != oldVal )
						}))
						this.unwatches = unwatches
					}).catch(error => console.log(error))
				}
			},
			save( callback ) {
				const ignored_online = [ "gender", "fname", "lname", "birthdate", "email", "phone" ]
				const isOnline = !!this.user.userid
				if ( Object.keys( this.changed ).length > 0 ) {
					this.error = {}
					let error = false
					let data = {}
					Object.keys( this.changed ).forEach( key => {
						if ( ( this.user[key] === undefined || this.user[key] === null || this.user[key].length == 0 ) && key != "email" ) {
							error = true
							this.error[key] = true
						} else if ( this.changed[key] && ( !isOnline || !ignored_online.includes(key) ) ) {
							data[key] = this.user[key]
						}
					})
					if ( error ) {
						callback( false, this.$t( 'errors.missing' ) )
						this.$forceUpdate()
					} else {
						let msg = null
						if ( !isOnline && ( !this.user.phone || !this.user.phone.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/) ) )  {
							this.error.phone = true
							msg = msg || this.$t( "errors.invalid.phone" )
						}
						if ( !isOnline && ( this.user.email && this.user.email.length > 0 && !this.user.email.match(/^[a-z0-9_\-.]+@[a-z0-9-.]+$/) ) ) {
							this.error.email = true
							msg = msg || this.$t( "errors.invalid.email" )
						}
						if ( Object.keys(this.error).length > 0 ) {
							callback( false, msg )
						} else {
							console.log( "data", data )
							this.$api.salonsuser.patch( this.user.id, data ).then( response => {
								setTimeout( () => {
									this.showSave = false
								}, 500 )
								this.canSave = false
								this.changed = {}
								callback( true )
							}).catch( error => {
								console.log( error )
								console.log( error.response.data )
								callback( false, this.$t( error.response.data.error ) )
								if ( error.response.data.args ) {
									error.response.data.args.forEach( field => {
										this.error[field] = true
									})
								}
								console.log( this.error )
								this.$forceUpdate()
							})
						}
					}
				}
			},
		},
		watch: {
			'$route.query.id'() {
				this.yearHistory = 1900 + new Date().getYear()
				this.update()
			},
			showDeleteConfirm( callback ) {
				if ( callback !== false ) {
					this.$nextTick( () => {
						this.$refs.deleteConfirm.$on( "confirm", () => {
							this.$refs.deleteConfirm.$off( 'confirm' )
							this.showDeleteConfirm = false
							this.$api.salonsuser.delete( this.user.id ).then( response => {
								callback( true )
								setTimeout( () => {
									this.$router.replace( "/userslist" )
								}, 2000)
							}).catch( error => {
								callback( false, this.$t( error.response.data.error ) )
							})
						})
						this.$refs.deleteConfirm.$on( "close", () => {
							this.$refs.deleteConfirm.$off( 'close' )
							this.showDeleteConfirm = false
							callback( -1 )
						})
					})
				}
			}
		},
		mounted() {
			$(this.$el).find('#birthdate').datetimepicker({
				"format": "DD MMM YYYY",
				"widgetPositioning": {"vertical":"bottom","horizontal":"right"},
				"debug": ( process.env.VUE_APP_MODE === "development" ),
				"icons": { "time": "fa fa-clock" },
				"locale": "fr",
				"maxDate": dayjs(),
				"useCurrent": false
			})
			$(this.$el).find('#birthdate').on( "change.datetimepicker", (e) => {
				this.user.birthdate = e.date.format( "YYYY-MM-DD" )
			})
			this.update()

			this.$api.reviews.watch( action => {
				if ( action.method == "POST" ) {
					for ( let i = 0; i < action.data.length; i++ ) {
						if ( action.data[i].userid == this.user.userid ) {
							this.reviews = [ action.data[i], ...this.reviews ]
						}
					}
				} else if ( action.method == "PATCH" ) {
					for ( let i = 0; i < action.data.length; i++ ) {
						let rev = this.reviews.find( r => r.id == action.data[i].id )
						if ( rev ) {
							Object.assign( rev, action.data[i] )
						}
					}
				} else if ( action.method == "DELETE" ) {
					for ( let i = 0; i < action.data.length; i++ ) {
						let rev = this.reviews.findIndex( r => r.id == action.data[i].id )
						if ( rev >= 0 ) {
							this.reviews.splice( rev, 1 )
						}
					}
				}
			})
		}
	}
</script>


<style scoped lang="scss" src="../css/pages/user.scss"/>

