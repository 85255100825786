<template>
	<Modal type="medium" name="dispute" class="dispute-review-modal">
		<template v-slot:header>
			<h1 class="title-search">{{ $t('index.surereview') }}</h1>
		</template>
		<template v-slot:content>
			<h2>{{ $t('reviews.dispute.reason') }} :</h2>
			<div class="dispute-reason">
				<div class="radio radio-primary">
					<input id="dispute-offensive" type="radio" class="d-none" name="dispute-reason" value="offensive" v-model="disputeReason"/>
					<label for="dispute-offensive">{{ $t('reviews.dispute.offensive') }}</label>
				</div>
				<div class="radio radio-primary">
					<input id="dispute-commercial" type="radio" class="d-none" name="dispute-reason" value="commercial" v-model="disputeReason"/>
					<label for="dispute-commercial">{{ $t('reviews.dispute.commercial') }}</label>
				</div>
				<div class="radio radio-primary">
					<input id="dispute-spam" type="radio" class="d-none" name="dispute-reason" value="spam" v-model="disputeReason"/>
					<label for="dispute-spam">{{ $t('reviews.dispute.spam') }}</label>
				</div>
			</div>
		</template>
		<template v-slot:buttons>
			<StatusButton id="validate" type="primary" alert="modal" @click="disputeReview($event)">{{ $t("common.dispute") }}</StatusButton>
			<button id="cancel" class="btn btn-gray" @click="$emit('close')" type="button">{{ $t("common.cancel") }}</button>
		</template>
	</Modal>
</template>

<script>
	import dayjs from "dayjs"
	import StatusButton from "../components/StatusButton.vue"
	import Modal from "../components/Modal.vue"


	export default {
		components: {
			StatusButton,
			Modal,
		},
		props: [
			"review",
			"value"
		],
		data() {
			return {
				disputeReason: null
			}
		},
		methods: {
			async disputeReview( callback ) {
				try {
					if ( dayjs(this.review.date).isAfter(dayjs().add(-7, 'day')) ) {
						if ( this.review.dispute && this.review.dispute.length > 0 ) {
							throw { message: "review.alreadydisputed" }
						}
						if ( !this.disputeReason ) {
							throw { message: "review.noreason" }
						}
						await this.$api.review.patch( this.review.id, { dispute: this.disputeReason } )
						callback( true )
						setTimeout( () => {
							this.$emit('close')
						}, 1000 )
					} else {
						callback( -1 )
					}
				} catch ( error ) {
					console.log( error )
					const tr = this.$t( "errors." + ( ( error.response || {data:{}}).data.error || error.message ) )
					callback( false, tr.indexOf("errors.") < 0 ? tr : error )
				}
			},
		}
	}
</script>

<style lang="scss" src="../css/modals/disputereview.scss"/>
